import React, { useState } from 'react';
import {
  TextField, Button, Grid, Typography, Paper, Box,
  Snackbar, Alert, Select, MenuItem, FormControl, InputLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Lista de estados brasileiros
const estados = [
  'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO',
  'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI',
  'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
];

// Lista de empresas disponíveis
const empresas = [
  { id: 1, nome: 'Aabb Engenharia' },
  { id: 2, nome: 'Chale' },
  { id: 3, nome: 'Concreteria' }
];

export default function CadastroCliente() {
  const [formData, setFormData] = useState({
    empresa_id: '',
    nome_completo: '',
    cpf_cnpj: '',
    rg_ie: '',
    data_nascimento: '',
    sexo: '',
    indicacao: '',
    telefone_fixo: '',
    celular: '',
    email: '',
    website: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    status_id: '1',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();
  // Função para limpar o formulário após o cadastro
  const resetForm = () => {
    setFormData({
      empresa_id: '',
      nome_completo: '',
      cpf_cnpj: '',
      rg_ie: '',
      data_nascimento: '',
      sexo: '',
      indicacao: '',
      telefone_fixo: '',
      celular: '',
      email: '',
      website: '',
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      status_id: '1',
    });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'empresa_id' ? (value ? parseInt(value) : '') : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cleanedData = { ...formData };
  
    // Converter strings vazias para null
    Object.keys(cleanedData).forEach((key) => {
      if (cleanedData[key] === '') cleanedData[key] = null;
    });
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/clientes.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(cleanedData),
      });
  
      console.log('Resposta da API:', response); // Log da resposta bruta
  
      const data = await response.json();
      if (!response.ok) {
        console.error('Erro na API:', data);
        setSnackbarMessage(data.message || 'Erro ao cadastrar cliente.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
  
      // Mensagem de sucesso e reset do formulário
      setSnackbarMessage(data.message);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      resetForm(); // Limpar o formulário após o sucesso

      setSnackbarMessage(data.message);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setTimeout(() => navigate('/clientes'), 1000);
    } catch (error) {
      console.error('Erro na requisição:', error);
      setSnackbarMessage('Erro na requisição ao servidor.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    
  };
  
  

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', padding: 2 }}>
      <Grid item xs={12} md={2} lg={12}>
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Typography variant="h5" align="center" marginBottom={2}>
            Cadastro de Cliente
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {/* Empresa */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Empresa</InputLabel>
                  <Select
                    name="empresa_id"
                    value={formData.empresa_id}
                    onChange={handleChange}
                  >
                    {empresas.map((empresa) => (
                      <MenuItem key={empresa.id} value={empresa.id}>
                        {empresa.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Nome Completo */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Nome Completo"
                  name="nome_completo"
                  value={formData.nome_completo}
                  onChange={handleChange}
                  required
                />
              </Grid>

              {/* CPF/CNPJ */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="CPF/CNPJ"
                  name="cpf_cnpj"
                  value={formData.cpf_cnpj}
                  onChange={handleChange}
                />
              </Grid>

              {/* RG/IE */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="RG/IE"
                  name="rg_ie"
                  value={formData.rg_ie}
                  onChange={handleChange}
                />
              </Grid>

              {/* Data de Nascimento */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Data de Nascimento"
                  name="data_nascimento"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={formData.data_nascimento}
                  onChange={handleChange}
                />
              </Grid>

              {/* Sexo */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Sexo</InputLabel>
                  <Select
                    name="sexo"
                    value={formData.sexo}
                    onChange={handleChange}
                  >
                    <MenuItem value="Masculino">Masculino</MenuItem>
                    <MenuItem value="Feminino">Feminino</MenuItem>
                    <MenuItem value="Outro">Outro</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Indicação */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Indicação"
                  name="indicacao"
                  value={formData.indicacao}
                  onChange={handleChange}
                />
              </Grid>

              {/* Telefone Fixo */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Telefone Fixo"
                  name="telefone_fixo"
                  value={formData.telefone_fixo}
                  onChange={handleChange}
                />
              </Grid>

              {/* Celular */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Celular"
                  name="celular"
                  value={formData.celular}
                  onChange={handleChange}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>

              {/* Website */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Website"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                />
              </Grid>

              {/* CEP */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="CEP"
                  name="cep"
                  value={formData.cep}
                  onChange={handleChange}
                />
              </Grid>

              {/* Endereço */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Endereço"
                  name="endereco"
                  value={formData.endereco}
                  onChange={handleChange}
                />
              </Grid>

              {/* Número e Complemento */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Número"
                  name="numero"
                  value={formData.numero}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Complemento"
                  name="complemento"
                  value={formData.complemento}
                  onChange={handleChange}
                />
              </Grid>

              {/* Bairro */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Bairro"
                  name="bairro"
                  value={formData.bairro}
                  onChange={handleChange}
                />
              </Grid>

              {/* Cidade e Estado */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Cidade"
                  name="cidade"
                  value={formData.cidade}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Estado</InputLabel>
                  <Select
                    name="estado"
                    value={formData.estado}
                    onChange={handleChange}
                  >
                    {estados.map((estado) => (
                      <MenuItem key={estado} value={estado}>
                        {estado}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
{/* Back Button */}
<Button 
              fullWidth 
              variant="outlined" 
              color="secondary" 
              sx={{ mt: 2 }} 
              onClick={() => navigate('/clientes')} // Navigate back to Clientes
            >
              Voltar
            </Button>
            <Button fullWidth type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
              Cadastrar
            </Button>
          </Box>
        </Paper>
      </Grid>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
