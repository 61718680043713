import React, { useEffect, useState } from 'react';
import {
  AppBar, Toolbar, Typography, Avatar, IconButton, Menu, MenuItem,
  Tooltip, Switch, FormControlLabel, Divider, useMediaQuery, Box,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Gradiente baseado na paleta da logo enviada
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundImage: 'linear-gradient(90deg, #1E4A7B 0%, #1C5B90 100%)', // Gradiente azul
  transition: theme.transitions.create(['background-color', 'color'], {
    duration: theme.transitions.duration.short,
  }),
}));

export default function Header({ onToggleSidebar, darkMode, setDarkMode }) {
  const [user, setUser] = useState({ nome: '', email: '' });
  const [currentTime, setCurrentTime] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    console.log('Token encontrado no localStorage:', token);

    if (!token) {
      console.log('Token ausente. Redirecionando para login.');
      navigate('/');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/user/me.php`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Dados do usuário:', data);
        setUser(data);
      } else {
        console.error('Erro de autenticação. Redirecionando para login.');
        handleLogout();
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      handleLogout();
    }
  };

  const updateTime = () => {
    const date = new Date().toLocaleTimeString('pt-BR', { timeZone: 'America/Cuiaba' });
    setCurrentTime(date);
  };

  useEffect(() => {
    fetchUserData();
    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleLogout = () => {
    console.log('Efetuando logout...');
    localStorage.removeItem('token');
    setUser({});
    navigate('/');
  };

  const timeStyle = {
    color: darkMode ? theme.palette.text.primary : '#fff', // Texto branco para destacar no gradiente
  };

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <IconButton edge="start" onClick={onToggleSidebar} sx={{ mr: 2 }}>
          <MenuIcon sx={{ color: '#fff' }} /> {/* Ícone de menu branco */}
        </IconButton>

        <Typography variant="h6" sx={{ flexGrow: 1, color: '#fff' }}>
          {user.nome}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginRight: 2 }}>
          <AccessTimeIcon sx={{ color: '#fff' }} /> {/* Ícone branco */}
          <Typography variant="subtitle1" sx={timeStyle}>
            {currentTime}
          </Typography>
        </Box>

        <Tooltip title={`Usuário: ${user.nome} (${user.email})`}>
          <IconButton onClick={handleMenuOpen} sx={{ p: 0, marginRight: 1.5 }}>
            <Avatar>{user.nome.charAt(0).toUpperCase()}</Avatar>
          </IconButton>
        </Tooltip>

        <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          <MenuItem disabled>
            <Typography variant="subtitle1">{user.nome}</Typography>
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => setDarkMode(!darkMode)}>
            <FormControlLabel control={<Switch checked={darkMode} />} label="Tema" />
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => navigate('/perfil')}>
            <AccountCircleIcon /> Perfil
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon /> Sair
          </MenuItem>
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
}
