import React, { useEffect, useState } from 'react';
import {
  Box, Button, IconButton, Paper, TextField, Grid, Tooltip, Typography,
  Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions,
  Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Delete, Edit, Visibility, Print, Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';

// Dados simulados para seleção
const categorias = ['Eletrônicos', 'Eletrodomésticos', 'Ferramentas'];
const subcategorias = ['Smartphones', 'Geladeiras', 'Parafusadeiras'];
const unidadesMedida = ['Unidade', 'Metro', 'Quilograma', 'Litro'];
const origensProduto = ['Nacional', 'Importado'];

export default function Produtos() {
  const [produtos, setProdutos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedProduto, setSelectedProduto] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const navigate = useNavigate();

  const fetchProdutos = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/produtos.php`);
      const data = await response.json();
      setProdutos(data);
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProdutos();
  }, []);

  const handleEdit = (produto) => {
    setSelectedProduto(produto);
    setIsEditMode(true);
    setModalOpen(true);
  };

  const handleView = (produto) => {
    setSelectedProduto(produto);
    setIsEditMode(false);
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_API_PHP_URL}/produtos.php`, {
        method: 'DELETE',
        body: JSON.stringify({ id }),
        headers: { 'Content-Type': 'application/json' },
      });
      fetchProdutos();
      setSnackbarMessage('Produto excluído com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Erro ao excluir produto.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSave = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_PHP_URL}/produtos.php`, {
        method: 'PUT',
        body: JSON.stringify(selectedProduto),
        headers: { 'Content-Type': 'application/json' },
      });
      setSnackbarMessage('Produto atualizado com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setModalOpen(false);
      fetchProdutos();
    } catch (error) {
      setSnackbarMessage('Erro ao atualizar produto.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduto((prev) => ({ ...prev, [name]: value }));
  };

  const exportToExcel = (rows) => {
    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Produtos');
    writeFile(workbook, 'produtos.xlsx');
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'nome_produto', headerName: 'Nome do Produto', flex: 1 },
    { field: 'categoria', headerName: 'Categoria', width: 150 },
    { field: 'preco_venda', headerName: 'Preço de Venda', width: 120 },
    { field: 'estoque_atual', headerName: 'Estoque Atual', width: 120 },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Visualizar">
            <IconButton onClick={() => handleView(params.row)}>
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton onClick={() => handleEdit(params.row)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Excluir">
            <IconButton onClick={() => handleDelete(params.row.id)}>
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Paper sx={{ padding: 3, margin: 3 }}>
      <Typography variant="h5" align="center" marginBottom={3}>
        Produtos Cadastrados
      </Typography>
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size="small"
            label="Buscar por Nome ou Categoria"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => navigate('/cadastro-produtos')}
              size="small"
            >
              Adicionar Produto
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Print />}
              onClick={() => window.print()}
              size="small"
            >
              Imprimir
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => exportToExcel(produtos)}
              size="small"
            >
              Exportar para Excel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DataGrid
        rows={produtos}
        columns={columns}
        loading={loading}
        pageSize={25}
        rowsPerPageOptions={[25]}
        autoHeight
        components={{ Toolbar: GridToolbar }}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>{isEditMode ? 'Editar Produto' : 'Visualizar Produto'}</DialogTitle>
        <DialogContent dividers>
          {selectedProduto && (
            <Grid container spacing={2}>
              {Object.entries(selectedProduto).map(([key, value]) => (
                <Grid item xs={6} key={key}>
                  <TextField
                    fullWidth
                    label={key.replace('_', ' ').toUpperCase()}
                    name={key}
                    value={value || ''}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {isEditMode && (
            <Button onClick={handleSave} color="primary">
              Salvar
            </Button>
          )}
          <Button onClick={() => setModalOpen(false)} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
