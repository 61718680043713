import React, { useEffect, useState } from 'react';
import {
  Box, Button, TextField, Grid, Typography, Paper, Modal,
  Snackbar, Alert, IconButton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Toolbar, MenuItem, Pagination, Breadcrumbs, Link
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


export default function ContasBancarias() {
  const [contasBancarias, setContasBancarias] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [banco, setBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [contaCorrente, setContaCorrente] = useState('');
  const [tipoConta, setTipoConta] = useState('Conta Corrente');
  const [descricao, setDescricao] = useState('');
  const [empresaId, setEmpresaId] = useState('');
  const [editingConta, setEditingConta] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);


  const apiUrl = 'https://athixdev.xyz/aabb/contas_bancarias.php';
  const navigate = useNavigate();


  const fetchContasBancarias = async (pageNumber = 1) => {
    const response = await axios.get(`${apiUrl}?page=${pageNumber}`);
    setContasBancarias(response.data);
    setTotalPages(Math.ceil(response.data.length / 12));
  };

  useEffect(() => {
    fetchContasBancarias(page);
  }, [page]);

  const handleSave = async () => {
    try {
      if (editingConta) {
        await axios.put(apiUrl, {
          id: editingConta.id,
          banco,
          agencia,
          conta_corrente: contaCorrente,
          tipo_conta: tipoConta,
          descricao,
          empresa_id: empresaId,
        });
        setSnackbarMessage('Conta bancária atualizada com sucesso!');
      } else {
        await axios.post(apiUrl, {
          banco,
          agencia,
          conta_corrente: contaCorrente,
          tipo_conta: tipoConta,
          descricao,
          empresa_id: empresaId,
        });
        setSnackbarMessage('Conta bancária adicionada com sucesso!');
      }

      setSnackbarOpen(true);
      setModalOpen(false);
      fetchContasBancarias();
      resetForm();
    } catch (error) {
      console.error('Erro ao salvar conta bancária:', error);
      setSnackbarMessage('Erro ao salvar conta bancária.');
      setSnackbarOpen(true);
    }
  };

  const handleEdit = (conta) => {
    setEditingConta(conta);
    setBanco(conta.banco);
    setAgencia(conta.agencia);
    setContaCorrente(conta.conta_corrente);
    setTipoConta(conta.tipo_conta);
    setDescricao(conta.descricao);
    setEmpresaId(conta.empresa_id);
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    await axios.delete(apiUrl, { data: { id } });
    setSnackbarMessage('Conta Bancária removida com sucesso!');
    setSnackbarOpen(true);
    fetchContasBancarias(page);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const resetForm = () => {
    setBanco('');
    setAgencia('');
    setContaCorrente('');
    setTipoConta('Conta Corrente');
    setDescricao('');
    setEmpresaId('');
    setEditingConta(null);
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Breadcrumbs para navegação */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link underline="hover" color="inherit" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          Módulo Financeiro
        </Link>
        <Typography color="text.primary">Contas Bancárias</Typography>
      </Breadcrumbs>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h6">Contas Bancárias</Typography>
        <Button variant="contained" color="success" onClick={() => setModalOpen(true)}>
          Adicionar Conta Bancária
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Toolbar>
          <Typography variant="h6">Lista de Contas Bancárias</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Banco</TableCell>
              <TableCell>Agência</TableCell>
              <TableCell>Conta Corrente</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contasBancarias.map((conta) => (
              <TableRow key={conta.id}>
                <TableCell>{conta.id}</TableCell>
                <TableCell>{conta.banco}</TableCell>
                <TableCell>{conta.agencia}</TableCell>
                <TableCell>{conta.conta_corrente}</TableCell>
                <TableCell>{conta.tipo_conta}</TableCell>
                <TableCell>{conta.descricao}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(conta)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(conta.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
      </Box>

      <Modal open={modalOpen} onClose={() => { setModalOpen(false); resetForm(); }}>
        <Paper
          sx={{
            width: '90%',
            maxWidth: 500,
            padding: 4,
            mx: 'auto',
            my: '5%',
            position: 'relative',
          }}
        >
          <IconButton aria-label="close" onClick={() => { setModalOpen(false); resetForm(); }} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" mb={2}>
            {editingConta ? 'Editar Conta Bancária' : 'Adicionar Conta Bancária'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                label="Empresa"
                fullWidth
                value={empresaId}
                onChange={(e) => setEmpresaId(e.target.value)}
              >
                <MenuItem value="1">AABB Engenharia</MenuItem>
                <MenuItem value="2">Concreteira</MenuItem>
                <MenuItem value="3">Chalé</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField label="Banco" fullWidth value={banco} onChange={(e) => setBanco(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Agência" fullWidth value={agencia} onChange={(e) => setAgencia(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Conta Corrente" fullWidth value={contaCorrente} onChange={(e) => setContaCorrente(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Tipo de Conta"
                fullWidth
                value={tipoConta}
                onChange={(e) => setTipoConta(e.target.value)}
              >
                <MenuItem value="Conta Corrente">Conta Corrente</MenuItem>
                <MenuItem value="Conta Poupança">Conta Poupança</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descrição"
                fullWidth
                multiline
                rows={4}
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                Salvar Conta Bancária
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
