import React from 'react';
import { Box, Typography, Link, Fab, useTheme } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export default function Footer() {
  const theme = useTheme(); // Acessa o tema atual (claro/escuro)
  const isDarkMode = theme.palette.mode === 'dark'; // Verifica se é modo escuro

  return (
    <>
      {/* Apenas o nome ATHIX à direita */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 8,
          right: 16,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2">
          <Link
            href="https://athix.com.br"
            target="_blank"
            rel="noopener"
            sx={{
              color: isDarkMode ? '#6cc24a' : '#4a90e2',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            ATHIX
          </Link>
        </Typography>
      </Box>

      {/* Ícone flutuante de calendário */}
      <Fab
        color="primary"
        aria-label="calendar"
        onClick={() => window.open('https://todoist.com/', '_blank')}
        sx={{
          position: 'fixed',
          bottom: 80,
          right: 16,
          backgroundColor: isDarkMode ? '#6cc24a' : '#4a90e2',
          color: '#fff',
          '&:hover': {
            backgroundColor: isDarkMode ? '#5ba832' : '#357ab8',
          },
        }}
      >
        <CalendarTodayIcon />
      </Fab>
    </>
  );
}
