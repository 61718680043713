import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

const initialEmpresas = [
  {
    id: 1,
    nome: 'Engenharia Ltda',
    cnpj: '00.000.000/0001-00',
    endereco: 'Rua Principal, 123',
    telefone: '(65) 99999-9999',
    email: 'contato@engenharia.com',
    status_id: 1,
  },
];

export default function Empresas() {
  const theme = useTheme();
  const [empresas, setEmpresas] = useState(initialEmpresas);
  const [openModal, setOpenModal] = useState(false);
  const [newEmpresa, setNewEmpresa] = useState({
    nome: '',
    cnpj: '',
    endereco: '',
    telefone: '',
    email: '',
  });

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setNewEmpresa({
      nome: '',
      cnpj: '',
      endereco: '',
      telefone: '',
      email: '',
    });
    setOpenModal(false);
  };

  const handleAddEmpresa = () => {
    const id = empresas.length + 1;
    const newEmpresaData = { ...newEmpresa, id };
    setEmpresas([...empresas, newEmpresaData]);
    handleCloseModal();
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'nome', headerName: 'Nome', width: 200 },
    { field: 'cnpj', headerName: 'CNPJ', width: 150 },
    { field: 'endereco', headerName: 'Endereço', width: 250 },
    { field: 'telefone', headerName: 'Telefone', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton>
            <VisibilityIcon color="primary" />
          </IconButton>
          <IconButton>
            <EditIcon color="action" />
          </IconButton>
          <IconButton>
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Gerenciamento de Empresas
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenModal}
        sx={{ marginBottom: 2 }}
      >
        Adicionar Empresa
      </Button>

      <DataGrid
        rows={empresas}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.mode === 'dark' ? 'primary.main' : '#f0f0f0',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
          },
          '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary,
          },
          '& .MuiDataGrid-root': {
            backgroundColor: theme.palette.background.paper,
          },
        }}
      />

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Adicionar Nova Empresa</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nome"
                fullWidth
                value={newEmpresa.nome}
                onChange={(e) =>
                  setNewEmpresa({ ...newEmpresa, nome: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="CNPJ"
                fullWidth
                value={newEmpresa.cnpj}
                onChange={(e) =>
                  setNewEmpresa({ ...newEmpresa, cnpj: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Endereço"
                fullWidth
                value={newEmpresa.endereco}
                onChange={(e) =>
                  setNewEmpresa({ ...newEmpresa, endereco: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Telefone"
                fullWidth
                value={newEmpresa.telefone}
                onChange={(e) =>
                  setNewEmpresa({ ...newEmpresa, telefone: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                value={newEmpresa.email}
                onChange={(e) =>
                  setNewEmpresa({ ...newEmpresa, email: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button onClick={handleAddEmpresa} color="primary">
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
