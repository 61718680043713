import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';

export default function ConfiguracaoEmail() {
  const [configEmail, setConfigEmail] = useState({
    email: '',
    senha: '',
    smtp: '',
    porta: '',
    enviarNotaFiscal: false,
    enviarNotaCancelamento: false,
    enviarAvisoCobranca: false,
    enviarNotificacaoProjeto: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setConfigEmail((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSave = () => {
    console.log('Configurações Salvas:', configEmail);
    alert('Configurações salvas com sucesso!');
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Configuração de E-mail e Notificações
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="E-mail"
            name="email"
            fullWidth
            value={configEmail.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Senha"
            type="password"
            name="senha"
            fullWidth
            value={configEmail.senha}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Servidor SMTP"
            name="smtp"
            fullWidth
            value={configEmail.smtp}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Porta"
            name="porta"
            fullWidth
            value={configEmail.porta}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ marginTop: 3 }}>
        Tipos de Notificação
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="enviarNotaFiscal"
              checked={configEmail.enviarNotaFiscal}
              onChange={handleChange}
            />
          }
          label="Enviar Nota Fiscal"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="enviarNotaCancelamento"
              checked={configEmail.enviarNotaCancelamento}
              onChange={handleChange}
            />
          }
          label="Enviar Nota Fiscal de Cancelamento"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="enviarAvisoCobranca"
              checked={configEmail.enviarAvisoCobranca}
              onChange={handleChange}
            />
          }
          label="Enviar Aviso de Cobrança"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="enviarNotificacaoProjeto"
              checked={configEmail.enviarNotificacaoProjeto}
              onChange={handleChange}
            />
          }
          label="Enviar Notificação de Projeto"
        />
      </FormGroup>

      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        onClick={handleSave}
      >
        Salvar Configurações
      </Button>
    </Box>
  );
}
