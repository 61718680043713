import React, { useState } from 'react';
import {
  TextField, Button, Grid, Typography, Paper, Box, Snackbar, Alert,
  Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const estados = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
const tiposFornecedor = ['Pessoa Jurídica', 'Pessoa Física'];
const tiposConta = ['Conta Corrente', 'Conta Poupança'];
const empresas = [{ id: 1, nome: 'Aabb Engenharia' }, { id: 2, nome: 'Chale' }, { id: 3, nome: 'Concreteria' }];

export default function CadastroFornecedor() {
  const [formData, setFormData] = useState({
    nome_razao_social: '', nome_fantasia: '', cnpj: '', inscricao_estadual: '',
    data_fundacao: '', tipo_fornecedor: '', telefone_fixo: '', celular: '',
    email: '', website: '', cep: '', endereco: '', numero: '', complemento: '',
    bairro: '', cidade: '', estado: '', ramo_atividade: '', banco: '', agencia: '',
    conta_corrente: '', tipo_conta: '', nome_representante: '', cpf_representante: '',
    telefone_representante: '', email_representante: '', empresa_id: ''
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  const resetForm = () => setFormData({
    nome_razao_social: '', nome_fantasia: '', cnpj: '', inscricao_estadual: '',
    data_fundacao: '', tipo_fornecedor: '', telefone_fixo: '', celular: '',
    email: '', website: '', cep: '', endereco: '', numero: '', complemento: '',
    bairro: '', cidade: '', estado: '', ramo_atividade: '', banco: '', agencia: '',
    conta_corrente: '', tipo_conta: '', nome_representante: '', cpf_representante: '',
    telefone_representante: '', email_representante: '', empresa_id: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/fornecedores.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (!response.ok) {
        setSnackbarMessage(data.message || 'Erro ao cadastrar fornecedor.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      setSnackbarMessage('Fornecedor cadastrado com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      resetForm();
      setTimeout(() => navigate('/fornecedores'), 1000);
    } catch (error) {
      setSnackbarMessage('Erro na requisição ao servidor.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', padding: 2 }}>
      <Grid item xs={12} md={10}>
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Typography variant="h5" align="center" marginBottom={2}>
            Cadastro de Fornecedor
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {/* Empresa */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Empresa</InputLabel>
                  <Select name="empresa_id" value={formData.empresa_id} onChange={handleChange}>
                    {empresas.map((empresa) => (
                      <MenuItem key={empresa.id} value={empresa.id}>{empresa.nome}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Nome Razão Social */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth label="Nome Razão Social" name="nome_razao_social"
                  value={formData.nome_razao_social} onChange={handleChange} required
                />
              </Grid>

              {/* Nome Fantasia */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth label="Nome Fantasia" name="nome_fantasia"
                  value={formData.nome_fantasia} onChange={handleChange}
                />
              </Grid>

              {/* CNPJ */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth label="CNPJ" name="cnpj"
                  value={formData.cnpj} onChange={handleChange} required
                />
              </Grid>

              {/* Tipo de Fornecedor */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Tipo de Fornecedor</InputLabel>
                  <Select name="tipo_fornecedor" value={formData.tipo_fornecedor} onChange={handleChange}>
                    {tiposFornecedor.map((tipo) => (
                      <MenuItem key={tipo} value={tipo}>{tipo}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Telefone Fixo e Celular */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth label="Telefone Fixo" name="telefone_fixo"
                  value={formData.telefone_fixo} onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth label="Celular" name="celular"
                  value={formData.celular} onChange={handleChange}
                />
              </Grid>

              {/* Endereço */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="CEP" name="cep" value={formData.cep} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Endereço" name="endereco" value={formData.endereco} onChange={handleChange} />
              </Grid>

              {/* Nome do Representante e CPF */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth label="Nome do Representante" name="nome_representante"
                  value={formData.nome_representante} onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth label="CPF do Representante" name="cpf_representante"
                  value={formData.cpf_representante} onChange={handleChange}
                />
              </Grid>
            </Grid>

            <Button fullWidth type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
              Cadastrar
            </Button>
          </Box>
        </Paper>
      </Grid>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
