import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Paper,
} from '@mui/material';

export default function Integracoes() {
  const [openAtalkModal, setOpenAtalkModal] = useState(false);
  const [openContaAzulModal, setOpenContaAzulModal] = useState(false);

  const handleOpenAtalkModal = () => setOpenAtalkModal(true);
  const handleCloseAtalkModal = () => setOpenAtalkModal(false);

  const handleOpenContaAzulModal = () => setOpenContaAzulModal(true);
  const handleCloseContaAzulModal = () => setOpenContaAzulModal(false);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Integrações
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Integração com Atalk
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAtalkModal}
            >
              Configurar Atalk
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Integração com Conta Azul
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenContaAzulModal}
            >
              Configurar Conta Azul
            </Button>
          </Paper>
        </Grid>
      </Grid>

      {/* Modal de Integração Atalk */}
      <Dialog open={openAtalkModal} onClose={handleCloseAtalkModal}>
        <DialogTitle>Configurar Integração com Atalk</DialogTitle>
        <DialogContent>
          <Typography>
            Insira as configurações necessárias para integrar com Atalk.
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth>
                Conectar Atalk
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAtalkModal}>Fechar</Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Integração Conta Azul */}
      <Dialog open={openContaAzulModal} onClose={handleCloseContaAzulModal}>
        <DialogTitle>Configurar Integração com Conta Azul</DialogTitle>
        <DialogContent>
          <Typography>
            Insira as configurações necessárias para integrar com Conta Azul.
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth>
                Conectar Conta Azul
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseContaAzulModal}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
