import React, { useState } from 'react';
import {
  TextField, Button, Grid, Typography, Paper, Box, Snackbar, Alert,
  Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Simulação de dados para campos de seleção
const categorias = ['Consultoria', 'Manutenção', 'Treinamento'];
const subcategorias = ['TI', 'Elétrica', 'RH'];
const unidadesMedida = ['Unidade', 'Hora', 'Dia', 'Metro Quadrado'];
const statusServico = ['Ativo', 'Inativo'];
const fornecedores = ['Fornecedor A', 'Fornecedor B', 'Fornecedor C'];
const retencaoTributos = ['Sim', 'Não'];

export default function CadastroServico() {
  const [formData, setFormData] = useState({
    codigo_servico: '', nome_servico: '', descricao_servico: '', categoria: '', subcategoria: '',
    unidade_medida: '', duracao_estimada: '', custo_servico: '', preco_venda: '',
    fornecedor_principal: '', custo_fornecedor: '', recursos_necessarios: '',
    observacoes: '', status_servico: 'Ativo', codigo_servico_fiscal: '', cst: '',
    cfop: '', aliquota_iss: '', retencao_tributos: 'Não'
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  const resetForm = () => setFormData({
    codigo_servico: '', nome_servico: '', descricao_servico: '', categoria: '', subcategoria: '',
    unidade_medida: '', duracao_estimada: '', custo_servico: '', preco_venda: '',
    fornecedor_principal: '', custo_fornecedor: '', recursos_necessarios: '',
    observacoes: '', status_servico: 'Ativo', codigo_servico_fiscal: '', cst: '',
    cfop: '', aliquota_iss: '', retencao_tributos: 'Não'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/servicos.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (!response.ok) {
        setSnackbarMessage(data.message || 'Erro ao cadastrar serviço.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      setSnackbarMessage('Serviço cadastrado com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      resetForm();
      setTimeout(() => navigate('/servicos'), 1000);
    } catch (error) {
      setSnackbarMessage('Erro na requisição ao servidor.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', padding: 2 }}>
      <Grid item xs={12} md={10}>
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Typography variant="h5" align="center" marginBottom={2}>
            Cadastro de Serviço
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {/* Código do Serviço */}
              <Grid item xs={12} md={6}>
                <TextField 
                  fullWidth label="Código do Serviço" name="codigo_servico" 
                  value={formData.codigo_servico} onChange={handleChange} required 
                />
              </Grid>

              {/* Nome do Serviço */}
              <Grid item xs={12} md={6}>
                <TextField 
                  fullWidth label="Nome do Serviço" name="nome_servico" 
                  value={formData.nome_servico} onChange={handleChange} required 
                />
              </Grid>

              {/* Categoria e Subcategoria */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Categoria</InputLabel>
                  <Select name="categoria" value={formData.categoria} onChange={handleChange}>
                    {categorias.map((categoria) => (
                      <MenuItem key={categoria} value={categoria}>{categoria}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Subcategoria</InputLabel>
                  <Select name="subcategoria" value={formData.subcategoria} onChange={handleChange}>
                    {subcategorias.map((sub) => (
                      <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Unidade de Medida e Duração Estimada */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Unidade de Medida</InputLabel>
                  <Select name="unidade_medida" value={formData.unidade_medida} onChange={handleChange}>
                    {unidadesMedida.map((unidade) => (
                      <MenuItem key={unidade} value={unidade}>{unidade}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField 
                  fullWidth label="Duração Estimada" name="duracao_estimada" 
                  value={formData.duracao_estimada} onChange={handleChange} 
                />
              </Grid>

              {/* Custo e Preço de Venda */}
              <Grid item xs={12} md={6}>
                <TextField 
                  fullWidth label="Custo do Serviço" name="custo_servico" type="number" 
                  value={formData.custo_servico} onChange={handleChange} 
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField 
                  fullWidth label="Preço de Venda" name="preco_venda" type="number" 
                  value={formData.preco_venda} onChange={handleChange} 
                />
              </Grid>

              {/* Fornecedor Principal */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Fornecedor Principal</InputLabel>
                  <Select name="fornecedor_principal" value={formData.fornecedor_principal} onChange={handleChange}>
                    {fornecedores.map((fornecedor) => (
                      <MenuItem key={fornecedor} value={fornecedor}>{fornecedor}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Observações e Recursos Necessários */}
              <Grid item xs={12}>
                <TextField 
                  fullWidth multiline rows={4} label="Recursos Necessários" name="recursos_necessarios" 
                  value={formData.recursos_necessarios} onChange={handleChange} 
                />
              </Grid>

              <Grid item xs={12}>
                <TextField 
                  fullWidth multiline rows={4} label="Observações" name="observacoes" 
                  value={formData.observacoes} onChange={handleChange} 
                />
              </Grid>
            </Grid>

            <Button fullWidth type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
              Cadastrar
            </Button>
          </Box>
        </Paper>
      </Grid>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
