import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function Certificados() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [empresa, setEmpresa] = useState('');

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setSelectedFile(null);
    setEmpresa('');
    setOpenModal(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && /\.(pfx|cer)$/i.test(file.name)) {
      setSelectedFile(file);
    } else {
      alert('Por favor, selecione um certificado válido (.pfx ou .cer).');
      setSelectedFile(null);
    }
  };

  const handleUpload = () => {
    if (!selectedFile || !empresa) {
      alert('Por favor, preencha todos os campos.');
      return;
    }
    console.log('Arquivo para upload:', selectedFile);
    handleCloseModal();
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Upload de Certificados Digitais
      </Typography>

      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        onClick={handleOpenModal}
        sx={{ marginBottom: 2 }}
      >
        Adicionar Certificado
      </Button>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Upload de Certificado Digital</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nome da Empresa"
                fullWidth
                value={empresa}
                onChange={(e) => setEmpresa(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                component="label"
                fullWidth
                startIcon={<CloudUploadIcon />}
                sx={{ marginTop: 1 }}
              >
                Selecionar Certificado
                <input
                  type="file"
                  hidden
                  accept=".pfx, .cer"
                  onChange={handleFileChange}
                />
              </Button>
              {selectedFile && (
                <Typography sx={{ marginTop: 1 }}>
                  Arquivo Selecionado: {selectedFile.name}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button onClick={handleUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
