import React from 'react';
import { Navigate } from 'react-router-dom';

// Função para verificar se o usuário está logado
const isAuthenticated = () => {
  const token = localStorage.getItem('token'); // Verifica se o token existe
  return !!token; // Retorna true se o token existir
};

// Rota protegida: Redireciona para login se não estiver autenticado
export default function ProtectedRoute({ children }) {
  return isAuthenticated() ? children : <Navigate to="/" />;
}
