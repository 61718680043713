import React, { useState } from 'react';
import {
  TextField, Button, Grid, Typography, Paper, Box, Snackbar, Alert,
  Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Dados simulados para seleção
const categorias = ['Eletrônicos', 'Eletrodomésticos', 'Ferramentas'];
const subcategorias = ['Smartphones', 'Geladeiras', 'Parafusadeiras'];
const unidadesMedida = ['Unidade', 'Metro', 'Quilograma', 'Litro'];
const origensProduto = ['Nacional', 'Importado'];
const empresas = [{ id: 1, nome: 'Aabb Engenharia' }, { id: 2, nome: 'Chale' }, { id: 3, nome: 'Concreteria' }];

export default function CadastroProduto() {
  const [formData, setFormData] = useState({
    codigo_produto: '', nome_produto: '', descricao_produto: '', categoria: '', subcategoria: '',
    marca: '', modelo: '', dimensoes: '', peso: '', material: '', cor: '', unidade_medida: '',
    preco_custo: '', preco_venda: '', estoque_minimo: '', estoque_atual: '', localizacao_estoque: '',
    fornecedor_principal: '', codigo_fornecedor: '', preco_compra_fornecedor: '', lead_time: '',
    imagem_principal: '', imagens_adicionais: '', codigo_barras: '', sku: '', ncm: '', observacoes: '',
    cfop: '', cst: '', origem_produto: '', aliquota_icms: '', aliquota_ipi: '', empresa_id: ''
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  const resetForm = () => setFormData({
    codigo_produto: '', nome_produto: '', descricao_produto: '', categoria: '', subcategoria: '',
    marca: '', modelo: '', dimensoes: '', peso: '', material: '', cor: '', unidade_medida: '',
    preco_custo: '', preco_venda: '', estoque_minimo: '', estoque_atual: '', localizacao_estoque: '',
    fornecedor_principal: '', codigo_fornecedor: '', preco_compra_fornecedor: '', lead_time: '',
    imagem_principal: '', imagens_adicionais: '', codigo_barras: '', sku: '', ncm: '', observacoes: '',
    cfop: '', cst: '', origem_produto: '', aliquota_icms: '', aliquota_ipi: '', empresa_id: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/produtos.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (!response.ok) {
        setSnackbarMessage(data.message || 'Erro ao cadastrar produto.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      setSnackbarMessage('Produto cadastrado com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      resetForm();
      setTimeout(() => navigate('/produtos'), 1000);
    } catch (error) {
      setSnackbarMessage('Erro na requisição ao servidor.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', padding: 2 }}>
      <Grid item xs={12} md={10}>
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Typography variant="h5" align="center" marginBottom={2}>
            Cadastro de Produto
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {/* Empresa */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Empresa</InputLabel>
                  <Select name="empresa_id" value={formData.empresa_id} onChange={handleChange}>
                    {empresas.map((empresa) => (
                      <MenuItem key={empresa.id} value={empresa.id}>{empresa.nome}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Código do Produto */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Código do Produto" name="codigo_produto" value={formData.codigo_produto} onChange={handleChange} required />
              </Grid>

              {/* Nome do Produto */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Nome do Produto" name="nome_produto" value={formData.nome_produto} onChange={handleChange} required />
              </Grid>

              {/* Categoria e Subcategoria */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Categoria</InputLabel>
                  <Select name="categoria" value={formData.categoria} onChange={handleChange}>
                    {categorias.map((categoria) => (
                      <MenuItem key={categoria} value={categoria}>{categoria}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Subcategoria</InputLabel>
                  <Select name="subcategoria" value={formData.subcategoria} onChange={handleChange}>
                    {subcategorias.map((sub) => (
                      <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Marca e Modelo */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Marca" name="marca" value={formData.marca} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Modelo" name="modelo" value={formData.modelo} onChange={handleChange} />
              </Grid>

              {/* Dimensões e Peso */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Dimensões (LxAxP)" name="dimensoes" value={formData.dimensoes} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Peso" name="peso" value={formData.peso} onChange={handleChange} />
              </Grid>

              {/* Preço de Custo e Preço de Venda */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Preço de Custo" name="preco_custo" type="number" value={formData.preco_custo} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Preço de Venda" name="preco_venda" type="number" value={formData.preco_venda} onChange={handleChange} />
              </Grid>

              {/* Código de Barras e SKU */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Código de Barras" name="codigo_barras" value={formData.codigo_barras} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="SKU" name="sku" value={formData.sku} onChange={handleChange} />
              </Grid>
            </Grid>

            <Button fullWidth type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
              Cadastrar
            </Button>
          </Box>
        </Paper>
      </Grid>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
