import React, { useEffect, useState } from 'react';
import {
  Box, Button, IconButton, Paper, TextField, Grid, Tooltip, Typography,
  Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions,
  Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Delete, Edit, Visibility, Print, Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';

const statusProjeto = ['Em Planejamento', 'Em Execução', 'Concluído', 'Cancelado'];
const tiposEdificacao = ['Loteamento', 'Comercial', 'Residencial', 'Industrial', 'Institucional', 'Barracão'];
const categoriasProjeto = ['Arquitetônico', 'Complementares', 'Prevenção e Incêndio', 'Urbanismo'];
const funcionarios = [{ id: 1, nome: 'João Silva' }, { id: 2, nome: 'Maria Souza' }]; 
const clientes = [{ id: 1, nome: 'Cliente A' }, { id: 2, nome: 'Cliente B' }];
const empresas = [{ id: 1, nome: 'Empresa A' }, { id: 2, nome: 'Empresa B' }];

export default function Projetos() {
  const [projetos, setProjetos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedProjeto, setSelectedProjeto] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const navigate = useNavigate();

  const fetchProjetos = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/projetos.php`);
      const data = await response.json();
      setProjetos(data);
    } catch (error) {
      console.error('Erro ao buscar projetos:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjetos();
  }, []);

  const handleEdit = (projeto) => {
    setSelectedProjeto(projeto);
    setIsEditMode(true);
    setModalOpen(true);
  };

  const handleView = (projeto) => {
    setSelectedProjeto(projeto);
    setIsEditMode(false);
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_API_PHP_URL}/projetos.php`, {
        method: 'DELETE',
        body: JSON.stringify({ id }),
        headers: { 'Content-Type': 'application/json' },
      });
      fetchProjetos();
      setSnackbarMessage('Projeto excluído com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Erro ao excluir projeto.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSave = async () => {
    try {
      const method = isEditMode ? 'PUT' : 'POST';
      await fetch(`${process.env.REACT_APP_API_PHP_URL}/projetos.php`, {
        method,
        body: JSON.stringify(selectedProjeto),
        headers: { 'Content-Type': 'application/json' },
      });
      setSnackbarMessage(isEditMode ? 'Projeto atualizado com sucesso.' : 'Projeto adicionado com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setModalOpen(false);
      fetchProjetos();
    } catch (error) {
      setSnackbarMessage('Erro ao salvar projeto.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedProjeto((prev) => ({ ...prev, [name]: value }));
  };

  const exportToExcel = (rows) => {
    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Projetos');
    writeFile(workbook, 'projetos.xlsx');
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'nome_projeto', headerName: 'Nome do Projeto', flex: 1 },
    { field: 'status_projeto', headerName: 'Status', width: 150 },
    { field: 'data_inicio', headerName: 'Data de Início', width: 150 },
    { field: 'data_termino_estimada', headerName: 'Término Estimado', width: 150 },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Visualizar">
            <IconButton onClick={() => handleView(params.row)}>
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton onClick={() => handleEdit(params.row)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Excluir">
            <IconButton onClick={() => handleDelete(params.row.id)}>
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Paper sx={{ padding: 3, margin: 3 }}>
      <Typography variant="h5" align="center" marginBottom={3}>
        Projetos Cadastrados
      </Typography>
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size="small"
            label="Buscar por Nome ou Status"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => {
                setSelectedProjeto({});
                setIsEditMode(false);
                setModalOpen(true);
              }}
              size="small"
            >
              Adicionar Projeto
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Print />}
              onClick={() => window.print()}
              size="small"
            >
              Imprimir
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => exportToExcel(projetos)}
              size="small"
            >
              Exportar para Excel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DataGrid
        rows={projetos}
        columns={columns}
        loading={loading}
        pageSize={25}
        rowsPerPageOptions={[25]}
        autoHeight
        components={{ Toolbar: GridToolbar }}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="md" fullWidth>
  <DialogTitle>{isEditMode ? 'Editar Projeto' : 'Adicionar Projeto'}</DialogTitle>
  <DialogContent dividers>
    <Grid container spacing={2}>
      {/* Nome do Projeto */}
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Nome do Projeto"
          name="nome_projeto"
          value={selectedProjeto?.nome_projeto || ''}
          onChange={handleChange}
        />
      </Grid>

      {/* Status do Projeto */}
      <Grid item xs={12} md={6}>
  <FormControl fullWidth required>
    <InputLabel>Status do Projeto</InputLabel>
    <Select
      name="status_projeto"
      value={selectedProjeto?.status_projeto || ''}
      onChange={handleChange}
    >
      {['Em Planejamento', 'Em Execução', 'Concluído', 'Cancelado'].map((status) => (
        <MenuItem key={status} value={status}>
          {status}
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  <Grid item xs={12} md={6}>
  <TextField
    fullWidth
    label="Código do Projeto"
    name="codigo_projeto"
    value={selectedProjeto?.codigo_projeto || ''}
    onChange={handleChange}
    required
  />
</Grid>
</Grid>
<Grid item xs={12} md={6}>
  <FormControl fullWidth required>
    <InputLabel>Empresa</InputLabel>
    <Select
      name="empresa_id"
      value={selectedProjeto?.empresa_id || ''}
      onChange={handleChange}
    >
      {empresas.map((empresa) => (
        <MenuItem key={empresa.id} value={empresa.id}>
          {empresa.nome}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>


      {/* Data de Início */}
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Data de Início"
          name="data_inicio"
          type="date"
          value={selectedProjeto?.data_inicio || ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      {/* Data de Término Estimada */}
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Data de Término Estimada"
          name="data_termino_estimada"
          type="date"
          value={selectedProjeto?.data_termino_estimada || ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      {/* Gerente do Projeto */}
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel>Gerente do Projeto</InputLabel>
          <Select
            name="gerente_id"
            value={selectedProjeto?.gerente_id || ''}
            onChange={handleChange}
          >
            {funcionarios.map((funcionario) => (
              <MenuItem key={funcionario.id} value={funcionario.id}>
                {funcionario.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Clientes Envolvidos (Seleção Múltipla) */}
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel>Clientes Envolvidos</InputLabel>
          <Select
            multiple
            name="clientes"
            value={selectedProjeto?.clientes || []}
            onChange={handleChange}
            renderValue={(selected) => selected.join(', ')}
          >
            {clientes.map((cliente) => (
              <MenuItem key={cliente.id} value={cliente.nome}>
                {cliente.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Orçamento */}
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Orçamento"
          name="orcamento"
          type="number"
          value={selectedProjeto?.orcamento || ''}
          onChange={handleChange}
        />
      </Grid>

      {/* Tipo de Edificação */}
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel>Tipo de Edificação</InputLabel>
          <Select
            name="tipo_edificacao"
            value={selectedProjeto?.tipo_edificacao || ''}
            onChange={handleChange}
          >
            {tiposEdificacao.map((tipo) => (
              <MenuItem key={tipo} value={tipo}>
                {tipo}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Categoria do Projeto */}
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel>Categoria do Projeto</InputLabel>
          <Select
            name="categoria_projeto"
            value={selectedProjeto?.categoria_projeto || ''}
            onChange={handleChange}
          >
            {categoriasProjeto.map((categoria) => (
              <MenuItem key={categoria} value={categoria}>
                {categoria}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Observações */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={4}
          label="Observações"
          name="observacoes"
          value={selectedProjeto?.observacoes || ''}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  </DialogContent>

  <DialogActions>
    <Button onClick={handleSave} color="primary">
      Salvar
    </Button>
    <Button onClick={() => setModalOpen(false)} color="secondary">
      Cancelar
    </Button>
  </DialogActions>
</Dialog>

    </Paper>
  );
}
