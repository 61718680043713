import React, { useEffect, useState } from 'react';
import {
  Box, Button, IconButton, Paper, TextField, Grid, Tooltip, Typography,
  Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions,
  Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Delete, Edit, Visibility, Print, Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';

const estados = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
const tiposFornecedor = ['Pessoa Jurídica', 'Pessoa Física'];
const tiposConta = ['Conta Corrente', 'Conta Poupança'];

export default function Fornecedores() {
  const [fornecedores, setFornecedores] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedFornecedor, setSelectedFornecedor] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();


  // Adicione esta função dentro do componente Fornecedores
const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedFornecedor((prev) => ({ ...prev, [name]: value }));
  };

  
  const fetchFornecedores = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/fornecedores.php`);
      const data = await response.json();
      setFornecedores(data);
    } catch (error) {
      console.error('Erro ao buscar fornecedores:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFornecedores();
  }, []);

  const handleEdit = (fornecedor) => {
    setSelectedFornecedor(fornecedor);
    setIsEditMode(true);
    setModalOpen(true);
  };

  const handleView = (fornecedor) => {
    setSelectedFornecedor(fornecedor);
    setIsEditMode(false);
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_API_PHP_URL}/fornecedores.php`, {
        method: 'DELETE',
        body: JSON.stringify({ id }),
        headers: { 'Content-Type': 'application/json' },
      });
      fetchFornecedores();
      setSnackbarMessage('Fornecedor excluído com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Erro ao excluir fornecedor.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSave = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_PHP_URL}/fornecedores.php`, {
        method: 'PUT',
        body: JSON.stringify(selectedFornecedor),
        headers: { 'Content-Type': 'application/json' },
      });
      setSnackbarMessage('Fornecedor atualizado com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setModalOpen(false);
      fetchFornecedores();
    } catch (error) {
      setSnackbarMessage('Erro ao atualizar fornecedor.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const exportToExcel = (rows) => {
    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Fornecedores');
    writeFile(workbook, 'fornecedores.xlsx');
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'nome_razao_social', headerName: 'Razão Social', flex: 1 },
    { field: 'cnpj', headerName: 'CNPJ', width: 150 },
    { field: 'cidade', headerName: 'Cidade', width: 150 },
    { field: 'estado', headerName: 'Estado', width: 100 },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Visualizar">
            <IconButton onClick={() => handleView(params.row)}>
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton onClick={() => handleEdit(params.row)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Excluir">
            <IconButton onClick={() => handleDelete(params.row.id)}>
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Paper sx={{ padding: 3, margin: 3 }}>
      <Typography variant="h5" align="center" marginBottom={3}>
        Fornecedores Cadastrados
      </Typography>
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size="small"
            label="Buscar por Razão Social ou CNPJ"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => navigate('/cadastro-fornecedor')}
              size="small"
            >
              Adicionar Fornecedor
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Print />}
              onClick={() => window.print()}
              size="small"
            >
              Imprimir
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => exportToExcel(fornecedores)}
              size="small"
            >
              Exportar para Excel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DataGrid
        rows={fornecedores}
        columns={columns}
        loading={loading}
        pageSize={25}
        rowsPerPageOptions={[25]}
        autoHeight
        components={{ Toolbar: GridToolbar }}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>{isEditMode ? 'Editar Fornecedor' : 'Visualizar Fornecedor'}</DialogTitle>
        <DialogContent dividers>
          {selectedFornecedor && (
            <Grid container spacing={2}>
              {Object.entries(selectedFornecedor).map(([key, value]) => (
                <Grid item xs={6} key={key}>
                  <TextField
                    fullWidth
                    label={key.replace('_', ' ').toUpperCase()}
                    name={key}
                    value={value || ''}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {isEditMode && (
            <Button onClick={handleSave} color="primary">
              Salvar
            </Button>
          )}
          <Button onClick={() => setModalOpen(false)} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
