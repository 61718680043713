import * as React from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Snackbar,
  IconButton,
  Tooltip,
  SvgIcon,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';

const defaultTheme = createTheme({
  palette: {
    primary: { main: '#7a7a7a' },
    background: { default: '#f0f4f8' },
  },
  typography: { fontFamily: 'Poppins, sans-serif' },
});

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Desenvolvido por © '}
      <a
        href="https://athix.com.br/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'inherit' }}
      >
        ATHIX
      </a>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

function TodoistIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm3 10.5a1 1 0 1 0 0 2h12a1 1 0 0 0 0-2H6Zm0-4a1 1 0 1 0 0 2h12a1 1 0 0 0 0-2H6Zm0-4a1 1 0 1 0 0 2h12a1 1 0 0 0 0-2H6Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function SignInSide() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
  const [openTodoistSnackbar, setOpenTodoistSnackbar] = React.useState(true);

  React.useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    const savedRememberMe = localStorage.getItem('rememberMe') === 'true';

    if (savedRememberMe) {
      setEmail(savedEmail || '');
      setPassword(savedPassword || '');
      setRememberMe(true);
    }

    const timer = setTimeout(() => setOpenTodoistSnackbar(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleSnackbarClose = () => setOpenSnackbar(false);

  const handleRememberMe = (event) => {
    const isChecked = event.target.checked;
    setRememberMe(isChecked);

    if (!isChecked) {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.removeItem('rememberMe');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/login.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailOuLogin: email, senha: password }),
      });
  
      const data = await response.json();
  
      if (response.ok && data.token) {
        localStorage.setItem('token', data.token);
        console.log('Token salvo:', data.token);
  
        setSnackbarMessage('Login efetuado com sucesso!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        navigate('/dashboard');
      } else {
        console.error('Erro ao receber token:', data.message);
        setSnackbarMessage(data.message || 'Erro ao receber token');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error('Erro na conexão com o servidor:', error);
      setSnackbarMessage('Erro na conexão com o servidor.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };
  
  

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{
          height: '100vh',
          backgroundImage:
            'url(https://img.freepik.com/fotos-gratis/silhueta-de-construcao_1127-3246.jpg?semt=ais_hybrid)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <CssBaseline />
        <Grid
          item
          xs={12}
          component={Paper}
          elevation={6}
          square
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(5px)',
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              p: 4,
              width: '100%',
              maxWidth: '400px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '8px',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.1)',
            }}
          >
            <img
              alt="Logo"
              src="https://aabbengenharia.com.br/wp-content/uploads/2022/09/thumb.png"
              style={{ width: '150px', marginBottom: '20px' }}
            />
            <Tooltip title="Acompanhe o progresso do projeto no Todoist" placement="top">
              <IconButton
                component="a"
                href="https://app.todoist.com/app/project/erp-6RHwQF9G8PXhPgvq"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: '#1976d2', marginBottom: '20px' }}
              >
                <TodoistIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="emailOuLogin"
                label="E-mail ou Nome de Usuário"
                name="emailOuLogin"
                autoComplete="username"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={
                  <Checkbox checked={rememberMe} onChange={handleRememberMe} color="primary" />
                }
                label="Lembrar-me"
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Entrar
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openTodoistSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenTodoistSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="info">Acompanhe o progresso no Todoist!</Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
