import React, { useEffect, useState } from 'react';
import {
  Box, Button, IconButton, Paper, TextField, Grid, Tooltip, Typography,
  Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Delete, Edit, Visibility, Print, Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';

export default function Clientes() {
  const [clientes, setClientes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const fetchClientes = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/tabelaClientes.php`);
      const data = await response.json();
      setClientes(data);
    } catch (error) {
      console.error('Erro ao buscar clientes:', error);
    } finally {
      setLoading(false);
    }
  };
  const filteredClientes = clientes.filter((cliente) => {
    const search = searchTerm.toLowerCase();
    
    return (
      (cliente.nome_completo && cliente.nome_completo.toLowerCase().includes(search)) ||
      (cliente.cpf_cnpj && cliente.cpf_cnpj.toLowerCase().includes(search)) ||
      (cliente.cidade && cliente.cidade.toLowerCase().includes(search)) ||
      (cliente.estado && cliente.estado.toLowerCase().includes(search))
    );
  });
  

  useEffect(() => {
    fetchClientes();
  }, []);

  const handleDelete = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_API_PHP_URL}/tabelaClientes.php`, {
        method: 'DELETE',
        body: JSON.stringify({ id }),
        headers: { 'Content-Type': 'application/json' },
      });
      fetchClientes();
      setSnackbarMessage('Cliente excluído com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Erro ao excluir cliente.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleView = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/clienteAcoes.php?id=${id}`);
      const data = await response.json();
      setSelectedCliente(data[0]);
      setModalOpen(true);
    } catch (error) {
      console.error('Erro ao buscar cliente:', error);
    }
  };

  const exportToExcel = (rows) => {
    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Clientes');
    writeFile(workbook, 'clientes.xlsx');
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'nome_completo', headerName: 'Nome Completo', flex: 1 },
    { field: 'cpf_cnpj', headerName: 'CPF/CNPJ', width: 150 },
    { field: 'cidade', headerName: 'Cidade', width: 150 },
    { field: 'estado', headerName: 'Estado', width: 100 },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Visualizar">
            <IconButton onClick={() => handleView(params.row.id)}>
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton onClick={() => navigate(`/tabelaClientes/edit/${params.row.id}`)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Excluir">
            <IconButton onClick={() => handleDelete(params.row.id)}>
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Paper sx={{ padding: 3, margin: 3 }}>
      <Typography variant="h5" align="center" marginBottom={3}>
        Clientes Cadastrados
      </Typography>
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size="small"
            label="Buscar por Nome ou CPF"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => navigate('/cadastro-clientes')}
              size="small"
            >
              Adicionar Cliente
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Print />}
              onClick={() => window.print()}
              size="small"
            >
              Imprimir
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => exportToExcel(clientes)}
              size="small"
            >
              Exportar para Excel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DataGrid
        rows={clientes}
        columns={columns}
        loading={loading}
        pageSize={25}
        rowsPerPageOptions={[25]}
        autoHeight
        components={{ Toolbar: GridToolbar }}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Detalhes do Cliente</DialogTitle>
        <DialogContent dividers>
          {selectedCliente && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography><strong>Nome Completo:</strong> {selectedCliente.nome_completo}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>CPF/CNPJ:</strong> {selectedCliente.cpf_cnpj}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>RG/IE:</strong> {selectedCliente.rg_ie}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Data de Nascimento:</strong> {selectedCliente.data_nascimento}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Sexo:</strong> {selectedCliente.sexo}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Indicação:</strong> {selectedCliente.indicacao}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Telefone Fixo:</strong> {selectedCliente.telefone_fixo}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Celular:</strong> {selectedCliente.celular}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Email:</strong> {selectedCliente.email}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Website:</strong> {selectedCliente.website}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Endereço:</strong> {selectedCliente.endereco}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Cidade:</strong> {selectedCliente.cidade}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Estado:</strong> {selectedCliente.estado}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Status:</strong> {selectedCliente.status_id}</Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
