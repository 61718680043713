import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

const empresas = ['Chalé', 'Engenharia', 'Concreteira'];
const perfis = ['SuperAdmin', 'Administrador da empresa', 'Usuário da empresa'];

const initialUsers = [
  {
    id: 1,
    nome: 'João Silva',
    email: 'joao@email.com',
    login: 'joao',
    perfil_id: 'Administrador da empresa',
    empresa_id: 'Engenharia',
    status_id: 1,
    data_criacao: '2023-10-01',
    ultimo_acesso: '2023-10-20',
  },
];

export default function Usuarios() {
  const theme = useTheme(); // Hook para acessar o tema atual
  const [users, setUsers] = useState(initialUsers);
  const [openModal, setOpenModal] = useState(false);
  const [newUser, setNewUser] = useState({
    nome: '',
    email: '',
    login: '',
    senha: '',
    perfil_id: '',
    empresa_id: '',
  });

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setNewUser({
      nome: '',
      email: '',
      login: '',
      senha: '',
      perfil_id: '',
      empresa_id: '',
    });
    setOpenModal(false);
  };

  const handleAddUser = () => {
    const id = users.length + 1;
    const newUserData = { ...newUser, id, data_criacao: new Date().toISOString() };
    setUsers([...users, newUserData]);
    handleCloseModal();
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'nome', headerName: 'Nome', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'login', headerName: 'Login', width: 150 },
    { field: 'perfil_id', headerName: 'Perfil', width: 200 },
    { field: 'empresa_id', headerName: 'Empresa', width: 150 },
    { field: 'data_criacao', headerName: 'Data Criação', width: 150 },
    { field: 'ultimo_acesso', headerName: 'Último Acesso', width: 150 },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton>
            <VisibilityIcon color="primary" />
          </IconButton>
          <IconButton>
            <EditIcon color="action" />
          </IconButton>
          <IconButton>
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Gerenciamento de Usuários
      </Typography>

      <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{ marginBottom: 2 }}>
        Adicionar Usuário
      </Button>

      <DataGrid
        rows={users}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.mode === 'dark' ? 'primary.main' : '#f0f0f0',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
          },
          '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary,
          },
          '& .MuiDataGrid-root': {
            backgroundColor: theme.palette.background.paper,
          },
        }}
      />

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Adicionar Novo Usuário</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nome"
                fullWidth
                value={newUser.nome}
                onChange={(e) => setNewUser({ ...newUser, nome: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Login"
                fullWidth
                value={newUser.login}
                onChange={(e) => setNewUser({ ...newUser, login: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Senha"
                type="password"
                fullWidth
                value={newUser.senha}
                onChange={(e) => setNewUser({ ...newUser, senha: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                value={newUser.perfil_id}
                onChange={(e) => setNewUser({ ...newUser, perfil_id: e.target.value })}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Selecione o Perfil
                </MenuItem>
                {perfis.map((perfil) => (
                  <MenuItem key={perfil} value={perfil}>
                    {perfil}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                value={newUser.empresa_id}
                onChange={(e) => setNewUser({ ...newUser, empresa_id: e.target.value })}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Selecione a Empresa
                </MenuItem>
                {empresas.map((empresa) => (
                  <MenuItem key={empresa} value={empresa}>
                    {empresa}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button onClick={handleAddUser} color="primary">
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
