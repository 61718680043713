import React, { useState } from 'react';
import {
  TextField, Button, Grid, Typography, Paper, Box, Snackbar, Alert,
  Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const estados = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
const empresas = [{ id: 1, nome: 'Aabb Engenharia' }, { id: 2, nome: 'Chale' }, { id: 3, nome: 'Concreteria' }];
const tiposContrato = ['CLT', 'Temporário', 'Estágio', 'Outro'];
const tiposConta = ['Conta Corrente', 'Conta Poupança'];
const opcoesSimNao = ['Sim', 'Não'];
const sexos = ['Masculino', 'Feminino', 'Outro'];
const estadosCivis = ['Solteiro', 'Casado', 'Divorciado', 'Viúvo', 'Outro'];

export default function CadastroFuncionario() {
  const [formData, setFormData] = useState({
    empresa_id: '', nome_completo: '', cpf: '', rg: '', data_nascimento: '',
    sexo: '', estado_civil: '', nacionalidade: '', naturalidade: '',
    telefone_fixo: '', celular: '', email: '', cep: '', endereco: '',
    numero: '', complemento: '', bairro: '', cidade: '', estado: '',
    cargo: '', departamento: '', data_admissao: '', salario: '',
    tipo_contrato: '', carga_horaria_semanal: '', supervisor_imediato: '',
    banco: '', agencia: '', conta_corrente: '', tipo_conta: '',
    ctps: '', pis_pasep: '', certificado_reservista: '', cnh: '',
    plano_saude: '', vale_refeicao: '', vale_transporte: '', outros_beneficios: '',
    observacoes: ''
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  const resetForm = () => setFormData({
    empresa_id: '', nome_completo: '', cpf: '', rg: '', data_nascimento: '',
    sexo: '', estado_civil: '', nacionalidade: '', naturalidade: '',
    telefone_fixo: '', celular: '', email: '', cep: '', endereco: '',
    numero: '', complemento: '', bairro: '', cidade: '', estado: '',
    cargo: '', departamento: '', data_admissao: '', salario: '',
    tipo_contrato: '', carga_horaria_semanal: '', supervisor_imediato: '',
    banco: '', agencia: '', conta_corrente: '', tipo_conta: '',
    ctps: '', pis_pasep: '', certificado_reservista: '', cnh: '',
    plano_saude: '', vale_refeicao: '', vale_transporte: '', outros_beneficios: '',
    observacoes: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/funcionarios.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (!response.ok) {
        setSnackbarMessage(data.message || 'Erro ao cadastrar funcionário.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      setSnackbarMessage('Funcionário cadastrado com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      resetForm();
      setTimeout(() => navigate('/funcionarios'), 1000);
    } catch (error) {
      setSnackbarMessage('Erro na requisição ao servidor.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', padding: 2 }}>
      <Grid item xs={12} md={10}>
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Typography variant="h5" align="center" marginBottom={2}>
            Cadastro de Funcionário
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {/* Empresa */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Empresa</InputLabel>
                  <Select name="empresa_id" value={formData.empresa_id} onChange={handleChange}>
                    {empresas.map((empresa) => (
                      <MenuItem key={empresa.id} value={empresa.id}>{empresa.nome}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Nome Completo */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Nome Completo" name="nome_completo" value={formData.nome_completo} onChange={handleChange} required />
              </Grid>

              {/* CPF */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="CPF" name="cpf" value={formData.cpf} onChange={handleChange} required />
              </Grid>

              {/* RG */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="RG" name="rg" value={formData.rg} onChange={handleChange} />
              </Grid>

              {/* Sexo */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Sexo</InputLabel>
                  <Select name="sexo" value={formData.sexo} onChange={handleChange}>
                    {sexos.map((sexo) => (
                      <MenuItem key={sexo} value={sexo}>{sexo}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Estado Civil */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Estado Civil</InputLabel>
                  <Select name="estado_civil" value={formData.estado_civil} onChange={handleChange}>
                    {estadosCivis.map((estado) => (
                      <MenuItem key={estado} value={estado}>{estado}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Nacionalidade e Naturalidade */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Nacionalidade" name="nacionalidade" value={formData.nacionalidade} onChange={handleChange} />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Naturalidade" name="naturalidade" value={formData.naturalidade} onChange={handleChange} />
              </Grid>

              {/* Telefone Fixo e Celular */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Telefone Fixo" name="telefone_fixo" value={formData.telefone_fixo} onChange={handleChange} />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Celular" name="celular" value={formData.celular} onChange={handleChange} />
              </Grid>

              {/* CEP, Endereço, Número, Complemento */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="CEP" name="cep" value={formData.cep} onChange={handleChange} />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Endereço" name="endereco" value={formData.endereco} onChange={handleChange} />
              </Grid>

              {/* Cargo e Departamento */}
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Cargo" name="cargo" value={formData.cargo} onChange={handleChange} />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField fullWidth label="Departamento" name="departamento" value={formData.departamento} onChange={handleChange} />
              </Grid>

              {/* Outros Campos */}
              <Grid item xs={12}>
                <TextField fullWidth multiline rows={4} label="Observações" name="observacoes" value={formData.observacoes} onChange={handleChange} />
              </Grid>
            </Grid>

            <Button fullWidth type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
              Cadastrar
            </Button>
          </Box>
        </Paper>
      </Grid>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
