import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Toolbar,
  Divider,
  Tooltip,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Popover,
  useTheme,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import { useNavigate } from 'react-router-dom';

export default function Sidebar({ open, onToggle, darkMode }) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const [cadastroAnchorEl, setCadastroAnchorEl] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const handleLogoutDialogOpen = () => setLogoutDialogOpen(true);
  const handleLogoutDialogClose = () => setLogoutDialogOpen(false);

  const handleSubmenuClick = (event) =>
    setSubmenuAnchorEl(submenuAnchorEl ? null : event.currentTarget);
  const handleSubmenuClose = () => setSubmenuAnchorEl(null);

  const handleCadastroClick = (event) =>
    setCadastroAnchorEl(cadastroAnchorEl ? null : event.currentTarget);
  const handleCadastroClose = () => setCadastroAnchorEl(null);

  const submenuOpen = Boolean(submenuAnchorEl);
  const cadastroOpen = Boolean(cadastroAnchorEl);

  const sidebarItems = [
    { text: 'Início', icon: <HomeIcon />, route: '/Dashboard' },
    { text: 'Financeiro', icon: <AccountBalanceIcon />, route: '/modulo-financeiro' },
  ];

  const cadastroItems = [
    { label: 'Clientes', route: '/Clientes' },
    { label: 'Funcionários', route: '/Funcionarios' },
    { label: 'Fornecedores', route: '/Fornecedores' },
    { label: 'Produtos', route: '/Produtos' },
    { label: 'Serviços', route: '/Servicos' },
    { label: 'Projetos', route: '/Projetos' },
  ];

  const configuracoesItems = [
    { label: 'Usuários', route: '/Usuarios' },
    { label: 'Parâmetros', route: '/parametros' },
    { label: 'Empresas', route: '/Empresas' },
    { label: 'E-mail', route: '/ConfiguracaoEmail' },
    { label: 'WhatsApp', route: '/whatsapp' },
    { label: 'Integrações', route: '/Integracoes' },
    { label: 'Certificado Digital', route: '/Certificados' },
  ];

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        open={open}
        sx={{
          width: open ? 240 : 72,
          transition: 'width 0.3s',
          '& .MuiDrawer-paper': {
            width: open ? 240 : 72,
            overflowX: 'hidden',
            background: isDarkMode
              ? 'linear-gradient(45deg, #333, #4a90e2)'
              : 'linear-gradient(45deg, #f0f4f8, #ffffff)',
            color: isDarkMode ? '#fff' : '#333333',
            boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 120,
            padding: '10px 0',
            transition: 'all 0.3s',
          }}
        >
          <IconButton onClick={onToggle} sx={{ color: '#4a90e2', mb: 1 }}>
            <MenuIcon />
          </IconButton>
          <img
            src="https://aabbengenharia.com.br/wp-content/uploads/2022/09/thumb.png"
            alt="Logo"
            style={{
              width: open ? '150px' : '40px',
              height: 'auto',
              transition: 'width 0.3s',
              marginTop: 20,
              marginBottom: 5,
            }}
          />
        </Toolbar>
        <Divider sx={{ backgroundColor: isDarkMode ? '#555' : '#e0e0e0' }} />

        <List>
          {sidebarItems.map((item, index) => (
            <Tooltip title={open ? '' : item.text} placement="right" key={index}>
              <ListItem
                button
                onClick={() => navigate(item.route)}
                sx={{
                  '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f5f5f5' },
                  margin: '4px 0',
                }}
              >
                <ListItemIcon sx={{ color: isDarkMode ? '#6cc24a' : '#4a90e2' }}>
                  {item.icon}
                </ListItemIcon>
                {open && <ListItemText primary={item.text} />}
              </ListItem>
            </Tooltip>
          ))}

          <Tooltip title={open ? '' : 'Cadastro'} placement="right">
            <ListItem
              button
              onClick={handleCadastroClick}
              sx={{ '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f5f5f5' }, margin: '4px 0' }}
            >
              <ListItemIcon sx={{ color: isDarkMode ? '#6cc24a' : '#4a90e2' }}>
                <PeopleIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Cadastro" />}
            </ListItem>
          </Tooltip>

          <Popover
            open={cadastroOpen}
            anchorEl={cadastroAnchorEl}
            onClose={handleCadastroClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <List disablePadding>
              {cadastroItems.map((item) => (
                <ListItem button key={item.label} onClick={() => navigate(item.route)}>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </Popover>

          <Tooltip title={open ? '' : 'Configurações'} placement="right">
            <ListItem
              button
              onClick={handleSubmenuClick}
              sx={{ '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f5f5f5' }, margin: '4px 0' }}
            >
              <ListItemIcon sx={{ color: isDarkMode ? '#6cc24a' : '#4a90e2' }}>
                <SettingsIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Configurações" />}
            </ListItem>
          </Tooltip>
        </List>

        <Divider sx={{ backgroundColor: isDarkMode ? '#555' : '#e0e0e0' }} />
        <List>
          <Tooltip title="Sair" placement="right">
            <ListItem
              button
              onClick={handleLogoutDialogOpen}
              sx={{ '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f5f5f5' }, margin: '4px 0' }}
            >
              <ListItemIcon sx={{ color: isDarkMode ? '#6cc24a' : '#4a90e2' }}>
                <LogoutIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Sair" />}
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>

      <Dialog open={logoutDialogOpen} onClose={handleLogoutDialogClose}>
        <DialogTitle>Deseja realmente sair?</DialogTitle>
        <DialogActions>
          <Button onClick={handleLogoutDialogClose}>Cancelar</Button>
          <Button onClick={handleLogout} color="error">
            Sair
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
